import { IMenuItem } from 'src/models/IMenu';
import { getLinkFromMenuItem } from 'shared/lib/getLinkFromMenuItem';

/** Получение колонок и ссылок для футера */
export const getFooterLinks = (menu: IMenuItem[], isExpress?: boolean) => {
  const catalogueLinks = menu?.map((item) => ({
    title: item.name || item.category.name,
    href: getLinkFromMenuItem(item, isExpress),
  }));

  return [
    // {
    //   title: 'Каталог',
    //   links: catalogueLinks,
    // },
    ...footerLinks,
  ];
};

const footerLinks = [
  {
    title: 'О компании',
    links: [
      {
        title: 'Блог',
        href: '/blog',
      },
      {
        title: 'Документы',
        href: '/documents',
      },
      {
        title: 'Контакты',
        href: '/kontakty',
      },
    ],
  },
  {
    title: 'Покупателям',
    links: [
      {
        title: 'Доставка',
        href: '/dostavka',
      },
      {
        title: 'Оплата',
        href: '/oplata',
      },
      {
        title: 'Возврат товаров',
        href: '/obmen-i-vozvrat',
      },
      {
        title: 'Вопросы и ответы',
        href: '/otvety-na-voprosy',
      },
      {
        title: 'Подъем и сборка',
        href: '/sborka',
      },
      {
        title: 'Политика конфиденциальности',
        href: '/politika-obrabotki-dannyh',
      },
      {
        title: 'Гарантия лучшей цены',
        href: '/garantiya-luchshey-ceny',
      },
      {
        title: 'Правила эксплуатации',
        href: '/docs/pravila_ekspluatacii.pdf',
        newTab: true,
      },
      {
        title: 'Политика обработки данных YandexCaptcha',
        href: 'https://yandex.ru/legal/smartcaptcha_notice/',
        newTab: true,
      },
    ],
  },
  {
    title: 'Партнерам',
    links: [
      {
        title: 'Информация для поставщиков',
        href: '/postavschikam-partneram',
      },
      {
        title: 'Стать партнером',
        href: '/apply-for-vendor',
      },
    ],
  },
];
