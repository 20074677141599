import React, {ChangeEvent, FC, KeyboardEvent, useState} from 'react';
import Link from 'next/link';

import { InputText } from '@nebo-team-dev/vobaza.ui.inputs.input-text/dist';
import SuccessModal from './SuccessModal';

import { api } from 'global/api';

import styles from './styles.module.scss';

export function checkEmail(value: string) {
  const patternEmail = new RegExp(/^([A-Za-zа-яА-ЯёЁ0-9_\-.])+@([A-Za-zа-яА-ЯёЁ0-9_\-.])+\.([A-Za-zа-яА-ЯёЁ]{2,4})$/);

  return patternEmail.test(value);
}

const Subscription: FC = () => {
  const [email, setEmail] = useState('');
  const [isSuccessSend, setSuccessSend] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const subscribeMailing = async () => {
    const isValid = checkEmail(email);

    if (!isValid) {
      setIsValidEmail(false);
      return;
    }

    setIsValidEmail(true);

    try {
      await api.subscribeMailing({ email });

      setSuccessSend(true);
      setEmail('');

      const activeElement = document.activeElement as HTMLInputElement;
      if (activeElement && activeElement.blur) activeElement.blur();
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    if (!isValidEmail) setIsValidEmail(true);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const isEnter = e.key === 'Enter' || e.code === 'Enter';

    if (isEnter) subscribeMailing();
  };

  const renderInput = ({withIcon}: { withIcon: boolean }) => {
     return (
         <InputText
             name="subscription"
             label="E-mail"
             isClickableIconWithValue={withIcon}
             value={email}
             error={!isValidEmail && 'Некорректный email'}
             onChange={handleChange}
             onClickIcon={withIcon ? subscribeMailing : null}
             onKeyUp={handleKeyUp}
         />
     )
  }

  return (
    <>
      {isSuccessSend && <SuccessModal onClose={() => setSuccessSend(false)} />}

      <div className={styles.subscription}>
        <div className={`${styles.subscriptionContainer} container`}>
          <div className={styles.subscriptionContent}>
            <h1 className={styles.subscriptionHeader}>Во выгода!</h1>
            <div className={styles.subscriptionTitle}>
              Подпишитесь и получайте промокоды, акции и подборки товаров на свою почту
            </div>
          </div>
          <div className={styles.subscriptionInput}>
            {renderInput({withIcon: true})}
            <button className={styles.subscriptInputButton} onClick={subscribeMailing}>
              Подписаться
            </button>
          </div>
          {/* Когда экран узкий */}
          <div className={styles.subscriptionInputMobile}>
            {renderInput({withIcon: false})}
            <button className={styles.subscriptButton} onClick={subscribeMailing}>
              Подписаться
            </button>
          </div>
          <div>
            <p className={styles.subscriptionConditionText}>
              Нажимая «Подписаться» вы соглашаетесь с
              <Link
                href="/politika-obrabotki-dannyh"
                prefetch={false}
                target="_blank"
                className={styles.subscriptionLink}
              >
                Условиями использования сайта
              </Link>{' '}
              и
              <Link
                href="/politika-obrabotki-dannyh"
                prefetch={false}
                target="_blank"
                className={styles.subscriptionLink}
              >
                Политикой обработки персональных данных.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
