import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategory } from 'entities/categories/model/ICategory';

export interface ICategoriesState {
  popularCategories: ICategory[];
  katalogCategories: ICategory[];
}

const initialState: ICategoriesState = {
  popularCategories: [],
  katalogCategories: [],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setPopularCategories: (state, action: PayloadAction<ICategory[]>) => {
      state.popularCategories = action.payload;
    },
    setKatalogCategories: (state, action: PayloadAction<ICategory[]>) => {
      state.katalogCategories = action.payload;
    },
  },
});

export const selectAllCategories = (state) => {
  return [
    ...new Set(
      state.categories?.katalogCategories
        .map((category: ICategory) => category.slug)
        .concat(state.categories?.popularCategories?.map((category) => category.slug) || [])
    ),
    'podborki',
  ];
};

export const { setPopularCategories, setKatalogCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
