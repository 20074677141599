import React, { FC, FormEvent, SyntheticEvent, useState } from 'react';
import { useRouter } from 'next/router';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';

import styles from './styles.module.scss';

const MobileSearch: FC = () => {
  const router = useRouter();
  const [value, setValue] = useState('');

  const setValueHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push({
      pathname: '/search',
      query: {
        query: value,
        limit: 40,
        page: 1,
      },
    });
    setValue('');
  };

  return (
    <>
      <div className={styles.mobileMargin}></div>
      <form className={`${styles.searchContainer} container`} onSubmit={onSubmit}>
        <input
          id="mobileSearchId"
          name="text"
          className={styles.search}
          value={value}
          onChange={setValueHandler}
          title="Найти товар или категорию..."
          placeholder="Все что нужно для дома"
        />
        <button className={styles.searchButton} type="submit" aria-label="Искать товар или категорию">
          <Icon className={styles.searchIcon} name="Magnifier"></Icon>
        </button>
      </form>
    </>
  );
};
export default MobileSearch;
