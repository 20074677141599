import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';
import { ChairSVG } from 'assets/svg/ChairSVG';

import { useSelector } from 'shared/lib/hooks/useSelector';
import { selectAllCategories } from 'src/store/categories';
import { ELinksPaths } from './resources/linksPaths';
import styles from './styles.module.scss';

type NavProps = { profileClickHandler: () => void; onClose: () => void };

export const SmallMobileNavigation = ({ profileClickHandler, onClose }: NavProps) => {
  const compareIds = useSelector((state) => state.goods.compareIds);
  const favoriteIds = useSelector((state) => state.goods.favoriteIds) || [];
  const cartSize = useSelector((state) => state.goods.cartSize);
  const user = useSelector((state) => state.auth.user);
  const categoriesNames = useSelector(selectAllCategories);
  const pathName = usePathname();
  const makeAmount = (amount: number): string => {
    return Boolean(amount) ? `(${amount})` : '';
  };

  const makeActiveClass = (path: ELinksPaths) => {
    if (path === ELinksPaths.katalog) {
      const regXp = /\/[\w-]+\/?/;
      const pathSlug = pathName?.match(regXp) ? pathName.match(regXp)[0].replaceAll('/', '') : '';
      return pathName?.match(path) || categoriesNames.includes(pathSlug) ? styles.activeLink : '';
    }
    return pathName?.match(path) ? styles.activeLink : '';
  };

  return (
    <nav className={styles.navContainer}>
      <hr />
      <Link
        onClick={onClose}
        className={`${styles.navContainer__item} ${makeActiveClass(ELinksPaths.katalog)}`}
        href={ELinksPaths.katalog}
      >
        <ChairSVG width={24} /> Каталог товаров
      </Link>
      <div onClick={profileClickHandler} className={styles.navContainer__item}>
        <Icon name="Person" />
        {user ? (
          <div>
            <p>
              {user.name} {user.surname}
            </p>
            <p>{user.phone}</p>
          </div>
        ) : (
          'Войти'
        )}
      </div>
      <Link
        onClick={onClose}
        className={`${styles.navContainer__item} ${makeActiveClass(ELinksPaths.wishList)}`}
        href={ELinksPaths.wishList}
      >
        <Icon name="Favorite" /> Избранное {makeAmount(favoriteIds.length)}
      </Link>
      <Link
        onClick={onClose}
        className={`${styles.navContainer__item} ${makeActiveClass(ELinksPaths.compare)}`}
        href={ELinksPaths.compare}
      >
        <Icon name="Compare" /> Список сравнений {makeAmount(compareIds.length)}
      </Link>
      <Link
        onClick={onClose}
        className={`${styles.navContainer__item} ${makeActiveClass(ELinksPaths.cart)}`}
        href={ELinksPaths.cart}
      >
        <Icon name="Cart" /> Корзина {makeAmount(cartSize)}
      </Link>

      <hr className={styles.navContainer__underline} />
    </nav>
  );
};
