import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import Link from 'next/link';

import type { IMenuItem } from 'src/models/IMenu';
import { useDispatch } from 'shared/lib/hooks/useDispatch';
import { useSelector } from 'shared/lib/hooks/useSelector';
import { toogleMobCatalog } from 'src/store/goods';
import { toggleModal } from 'src/store/auth';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';
import { CollapsingMenu, CollapsingLoadMenu } from '../CollapsingMenu';
import { HeaderMobileMenu } from '../MobileMenu';
import Search from '../Search';
import MobileSearch from '../Search/MobileSearch';
import styles from './styles.module.scss';

type Props = {
  onHoverBurger: () => Promise<IMenuItem[]>;
};

export const HeaderBody: FC<Props> = ({ onHoverBurger }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSideMenuFetching, setSideMenuFetching] = useState(false);

  const activeMobCatalog = useSelector((state) => state.goods.activeMobCatalog);
  const favoriteIds = useSelector((state) => state.goods.favoriteIds);
  const compareIds = useSelector((state) => state.goods.compareIds);
  const cartSize = useSelector((state) => state.goods.cartSize);
  const dispatch = useDispatch();

  const [currentMenu, setCurrentMenu] = useState<IMenuItem | IMenuItem[]>(null);
  const [withRoot, setWithRoot] = useState(false);
  const [itemEvent, setItemEvent] = useState<React.MouseEvent<HTMLAnchorElement> | null>(null);

  let timer: NodeJS.Timeout;

  const router = useRouter();
  const isExpress = router.asPath.includes('/ekspress-dostavka');
  const katalogLink = isExpress ? '/katalog/ekspress-dostavka' : '/katalog';

  const handleMouseEnter = (e: React.MouseEvent<HTMLAnchorElement>) => {
    timer = setTimeout(() => {
      setItemEvent(e);
    }, 500);
  };

  const closeMenu = () => {
    setWithRoot(false);
    setCurrentMenu(null);
  };

  const openMenuAll = async () => {
    let sideMenu: IMenuItem[] = [];

    setSideMenuFetching(true);
    sideMenu = await onHoverBurger();
    setSideMenuFetching(false);

    setWithRoot(true);
    setCurrentMenu(sideMenu);
  };

  useEffect(() => {
    if (itemEvent) {
      openMenuAll();
    }
  }, [itemEvent]);

  const isUserLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const token = Cookies.get('token');

  const openAuthModal = () => {
    dispatch(toggleModal());
  };
  const toggleMenu = (value?: any) => {
    if (value === undefined) {
      dispatch(toogleMobCatalog(!activeMobCatalog));
    } else {
      dispatch(toogleMobCatalog(Boolean(value)));
    }
  };

  useEffect(() => {
    if (!isUserLoggedIn) {
      setIsLoggedIn(Boolean(token));
    } else {
      setIsLoggedIn(isUserLoggedIn);
    }
  }, [isUserLoggedIn, token]);

  useEffect(() => {
    toggleMenu(false);
  }, [router.asPath]);

  const isSideMenu = currentMenu instanceof Array;

  return (
    <div className={styles.headerContainer}>
      <HeaderMobileMenu isOpen={activeMobCatalog} close={toggleMenu} />
      <div className={styles.mainHeader}>
        <div className={styles.mainHeaderMobile}>
          <button className={`${styles.burgerMenu}  ${styles.burgerMenuHide}`} onClick={toggleMenu}>
            <Icon name="MenuBurger" />
          </button>
          <Link href="/" className={styles.logo} aria-label="Лого">
            <Icon name="VobazaLogo" />
          </Link>
          <div className={styles.mainHeaderMobile__rightSide}>
            <button className={styles.burgerMenu} onClick={toggleMenu}>
              <Icon name="MenuBurger" />
            </button>
            <Link href="/profile/wishlist" className={styles.mainHeaderMobile__iconWithCounter}>
              {Boolean(favoriteIds?.length) && (
                <div className={styles.mainHeaderMobile__iconCounter}>{favoriteIds.length}</div>
              )}
              <Icon name="Favorite" className={styles.mainHeaderMobile__button} />
            </Link>
            <Link href="/cart" className={styles.mainHeaderMobile__iconWithCounter}>
              {Boolean(cartSize) && <div className={styles.mainHeaderMobile__iconCounter}>{cartSize}</div>}
              <Icon name="Cart" className={`${styles.mainHeaderMobile__button} ${styles.mainHeaderMobile__cartIcon}`} />
            </Link>
            <a href="tel:+74958990909">
              <Icon name="Phone" className={styles.mainHeaderMobile__button} />
            </a>
          </div>
        </div>
        <div onMouseLeave={closeMenu}>
          {isSideMenuFetching && <CollapsingLoadMenu />}
          {(isSideMenu || (!isSideMenu && currentMenu?.children)) && !isSideMenuFetching && (
            <CollapsingMenu menu={currentMenu} withRoot={isSideMenu} closeMenu={closeMenu} />
          )}
          <Link
            href={katalogLink}
            className={`${styles.headerCategory} ${styles.headerCategoryAll} ${withRoot ? styles.active : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => clearTimeout(timer)}
            onClick={() => {
              closeMenu();
            }}
          >
            <Icon name="MenuBurger" />
            Все товары
          </Link>
        </div>
        <div className={styles.headerSearch}>
          <Search />
        </div>
        <div className={styles.headerButtons}>
          {isLoggedIn ? (
            <div className={styles.headerButton}>
              <Link href="/profile" className={styles.headerButton}>
                <Icon name="Person"></Icon>
                <span>Профиль</span>
              </Link>
            </div>
          ) : (
            <div className={styles.headerButton} onClick={openAuthModal}>
              <Icon name="Person"></Icon>
              <span>Войти</span>
            </div>
          )}
          <Link href="/profile/wishlist" className={styles.headerButton}>
            <Icon name="Favorite"></Icon>
            <span>Избранное</span>
            {favoriteIds && favoriteIds.length > 0 && (
              <span className={styles.headerButtonBadge}>{favoriteIds.length}</span>
            )}
          </Link>
          <Link href="/compare" className={styles.headerButton}>
            <Icon name="Compare"></Icon>
            <span>Сравнение</span>
            {compareIds && compareIds.length > 0 && (
              <span className={styles.headerButtonBadge}>{compareIds.length}</span>
            )}
          </Link>
          <Link href="/cart" className={styles.headerButton}>
            <Icon name="Cart"></Icon>
            <span>Корзина</span>
            {cartSize > 0 && <span className={`${styles.headerButtonBadge} ${styles.badgeBasket}`}>{cartSize}</span>}
          </Link>
        </div>
      </div>
      <MobileSearch />
    </div>
  );
};
