import React, { FC } from 'react';
import VkIcon from './VkIcon';
import styles from './styles.module.scss';
import SKIcon from './SKIcon';

const Credentials: FC = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className={styles.social}>
        <a href="https://vk.com/vobaza_official" target="_blank" className={styles.socialItem} rel="noreferrer" aria-label="Сообщество Vobaza в VK">
          <VkIcon />
        </a>
      </div>
      <div className={`${styles.credentials} container`}>
        <div className={styles.copyright}>&copy;&nbsp;{year}&nbsp;ООО &laquo;Вобаза&raquo;</div>
        <a href="https://navigator.sk.ru/orn/1123813" target="_blank" className={styles.socialItem} rel="noreferrer">
          <SKIcon />
        </a>
      </div>
    </>
  );
};

export default Credentials;
