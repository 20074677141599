import React, { FC } from 'react';

const VkIcon: FC = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.5977C0 6.17651 0 3.46593 1.68413 1.78179C3.36827 0.0976562 6.07885 0.0976562 11.5 0.0976562H12.5C17.9212 0.0976562 20.6318 0.0976562 22.3158 1.78179C24 3.46593 24 6.17651 24 11.5977V12.5977C24 18.0188 24 20.7294 22.3158 22.4135C20.6318 24.0977 17.9212 24.0977 12.5 24.0977H11.5C6.07885 24.0977 3.36827 24.0977 1.68413 22.4135C0 20.7294 0 18.0188 0 12.5977L0 11.5977Z"
        fill="#2787F5"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50045 7.59766H4.75005C4.24993 7.59766 4.1499 7.83306 4.1499 8.09256C4.1499 8.55611 4.74333 10.8551 6.913 13.8956C8.35945 15.9721 10.3974 17.0977 12.2518 17.0977C13.3645 17.0977 13.5021 16.8477 13.5021 16.4171V14.8477C13.5021 14.3477 13.6075 14.2479 13.9598 14.2479C14.2195 14.2479 14.6645 14.3777 15.703 15.3788C16.8899 16.5654 17.0855 17.0977 17.753 17.0977H19.5035C20.0036 17.0977 20.2537 16.8477 20.1094 16.3543C19.9515 15.8626 19.3849 15.1492 18.633 14.3035C18.225 13.8215 17.6131 13.3024 17.4276 13.0428C17.168 12.7091 17.2422 12.5607 17.4276 12.2641C17.4276 12.2641 19.5602 9.26061 19.7827 8.24096C19.894 7.87011 19.7828 7.59766 19.2534 7.59766H17.503C17.058 7.59766 16.8527 7.83306 16.7414 8.09256C16.7414 8.09256 15.8514 10.2618 14.5904 11.6708C14.1824 12.0787 13.9969 12.2085 13.7744 12.2085C13.6631 12.2085 13.5021 12.0787 13.5021 11.7079V8.24096C13.5021 7.79596 13.3729 7.59766 13.002 7.59766H10.2513C9.9732 7.59766 9.8059 7.80416 9.8059 7.99991C9.8059 8.42171 10.4364 8.51901 10.5014 9.70556V12.2827C10.5014 12.8477 10.3993 12.9501 10.1768 12.9501C9.5834 12.9501 8.13995 10.7712 7.28385 8.27801C7.1161 7.79341 6.9478 7.59766 6.50045 7.59766Z"
        fill="white"
      ></path>
    </svg>
  );
};

export default VkIcon;
