import React, { FC, useState, MouseEvent, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import type { IMenuItem } from 'src/models/IMenu';
import { getLinkFromMenuItem } from 'shared/lib/getLinkFromMenuItem';
import CitySelect from '../CitySelect';
import MenuPreloader from 'shared/ui/Preloader/MenuPreloader';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon';
import { CollapsingMenu } from '../CollapsingMenu';

import styles from './styles.module.scss';

type Props = {
  mainMenu?: IMenuItem[];
  onHoverBurger: () => Promise<IMenuItem[]>;
  openPhoneCallModal: () => void;
};

export const HeaderMenu: FC<Props> = ({ mainMenu, openPhoneCallModal }) => {
  const [currentMenu, setCurrentMenu] = useState<IMenuItem | IMenuItem[]>(null);

  const openFullMenu = (e: any) => {
    if (e && mainMenu[e.target.dataset.index]) {
      setCurrentMenu(mainMenu[e.target.dataset.index]);
    } else {
      setCurrentMenu(null);
    }
  };

  const closeMenu = () => {
    setCurrentMenu(null);
  };

  const isSideMenu = currentMenu instanceof Array;

  return (
    <div className={`${styles.menuContainer}`} onMouseLeave={closeMenu}>
      <div className={styles.headerCity}>
        <CitySelect />
      </div>
      {(isSideMenu || (!isSideMenu && currentMenu?.children)) && (
        <CollapsingMenu menu={currentMenu} withRoot={isSideMenu} closeMenu={closeMenu} />
      )}

      <nav className={styles.menu}>
        {!mainMenu ? (
          <MenuPreloader />
        ) : (
          <div className={styles.headerMenuItems}>
            {mainMenu?.map((item, index) => (
              <HeaderMenuItem
                key={item.id}
                index={index}
                item={item}
                openFullMenu={openFullMenu}
                closeMenu={closeMenu}
              />
            ))}
          </div>
        )}
      </nav>
      <div className={styles.phoneWraper}>
        <div className={styles.phoneLink} onClick={openPhoneCallModal}>
          Заказать звонок
        </div>
        <div className={styles.phone}>8-800-505-53-55</div>
      </div>
      <a className={styles.headerMobileMenuPhone} href="tel:+74951725625" aria-label="Позвонить">
      {/* <a className={styles.headerMobileMenuPhone} href="tel:+88005055355"> */}
        <Icon name="Phone" />
      </a>
    </div>
  );
};

type MainMenuItemProps = {
  index: number;
  item: IMenuItem;
  openFullMenu: (e: MouseEvent<HTMLAnchorElement>) => void;
  closeMenu: () => void;
};

const HeaderMenuItem = ({ index, item, openFullMenu, closeMenu }: MainMenuItemProps) => {
  const router = useRouter();
  const isExpress = router.asPath.includes('/ekspress-dostavka');
  const link = getLinkFromMenuItem(item, isExpress);

  const [itemEvent, setItemEvent] = useState<MouseEvent<HTMLAnchorElement> | null>(null);
  let timer: NodeJS.Timeout;

  const handleMouseEnter = (e: MouseEvent<HTMLAnchorElement>) => {
    timer = setTimeout(() => {
      setItemEvent(e);
    }, 500);
  };

  useEffect(() => {
    if (itemEvent) {
      openFullMenu(itemEvent);
    }
  }, [itemEvent]);

  return (
    <Link
      href={link}
      className={` ${styles.headerCategory} ${router.asPath.includes(link) ? styles.active : ''} `}
      data-index={index}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => clearTimeout(timer)}
      onClick={() => {
        setItemEvent(null);
        closeMenu();
      }}
    >
      {item.name}
    </Link>
  );
};
