import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {createWrapper} from 'next-redux-wrapper';

import filtersReducer, {IFiltersState} from './filters';
import tagsReducer, {ITagsState} from './tags';
import goodsReducer, {IGoodsState} from './goods';
import authReducer, {IAuthsState} from './auth';
import menusReducer, {IMenuState} from './menus';
import categoriesReducer, {ICategoriesState} from './categories';

export interface IRootState {
    auth: IAuthsState,
    tags: ITagsState,
    filters: IFiltersState,
    goods: IGoodsState,
    menus: IMenuState,
    categories: ICategoriesState,
}

export const rootReducer = combineReducers<IRootState>({
    auth: authReducer,
    tags: tagsReducer,
    filters: filtersReducer,
    goods: goodsReducer,
    menus: menusReducer,
    categories: categoriesReducer,
})

export const getStore = () => configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
    reducer: rootReducer,
});

const store = getStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export const wrapper = createWrapper(getStore);