import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  openPhoneCallModal: () => void;
};

const BannerBlock: FC<Props> = ({ openPhoneCallModal }) => {
  return (
    <>
      <div className={styles.BannerBlockContainer}>
        <h1 className={styles.BannerBlockHeader}>Во Оформление!</h1>
        <p className={styles.BannerBlockText}>
          Оставьте телефон при оформлении заказа – <br />
          мы свяжемся с вами для уточнения деталей
        </p>
      </div>
      <div className={styles.BannerBlockContainerWidth}>
        <p className={styles.BannerBlockTextWidth}>
          Есть вопросы? Звоните:
          <a href="tel:+74958990909" onClick={openPhoneCallModal}>
            {' '}
            8 (495) 899-09-09{' '}
          </a>
          <span className={styles.workTime}>с&nbsp;9:00 до&nbsp;18:00, пн.— пт.</span>
          <span>
            Электронная почта
            <a href="mailto:info@vobaza.ru" className={styles.mailInfo}>
              {' '}
              info@vobaza.ru
            </a>
          </span>
        </p>
      </div>
    </>
  );
};

export default BannerBlock;
