import { useRouter } from 'next/router';
import React, { FC } from 'react';
import Link from 'next/link';

import { useSelector } from 'shared/lib/hooks/useSelector';
import { getFooterLinks } from './lib/getFooterLinks';

import VkIcon from '../Credentials/VkIcon';
import { Icon } from '@nebo-team-dev/vobaza.ui.icon';
import SKIcon from '../Socials/SKIconPrimary';
import Accordeon from 'shared/ui/Accordeon';

import styles from './styles.module.scss';

interface ICatalogueLink {
  title: string;
  links: {
    title: string;
    href: string;
    newTab?: boolean;
  }[];
}

const MainFooter: FC = () => {
  const mainMenu = useSelector((state) => state.menus.mainMenu);
  const router = useRouter();
  const isExpress = router.asPath.includes('/ekspress-dostavka');

  const catalogueLinks: ICatalogueLink[] = getFooterLinks(mainMenu, isExpress);
  const year = new Date().getFullYear();

  return (
    <>
      <div className={styles.mainFooter}>
        <div className="container">
          <div className={styles.mainFooterContent}>
            {catalogueLinks.map((item) => (
              <div className={styles.mainFooterColumn} key={item.title}>
                <div className={styles.mainFooterColumnTitle}>{item.title}</div>
                {item.links.map((link) => (
                  <div className={styles.mainFooterColumnItem} key={link.title} style={{ lineHeight: 'initial' }}>
                    <Link
                      href={link.href}
                      key={link.title}
                      className={styles.mainFooterColumnLink}
                      target={link.newTab ? '_blank' : '_self'}
                    >
                      {link.title}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
            <div className={styles.credentials}>
              <Icon name="VobazaLogo" />
              <div className={styles.copyright}>&copy;&nbsp;{year}&nbsp;ООО &laquo;Вобаза&raquo;</div>
              <div className={styles.icons}>
                <a href="https://vk.com/vobaza_official" target="_blank" className={styles.socialItem} rel="noreferrer" aria-label="Сообщество Vobaza в VK">
                  <VkIcon />
                </a>
                <a
                  href="https://navigator.sk.ru/orn/1123813"
                  target="_blank"
                  className={styles.socialItem}
                  rel="noreferrer"
                  aria-label="Vobaza на сайте Skolkovo"
                >
                  <SKIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mainFooterContentPhone}>
          {catalogueLinks.map((item) => (
            <Accordeon key={item.title} title={item.title}>
              {item.links.map((link) => (
                <div className={styles.mainFooterColumnItem} key={link.title} style={{ lineHeight: 'initial' }}>
                  <Link
                    href={link.href}
                    key={link.title}
                    className={styles.mainFooterColumnLink}
                    target={link.newTab ? '_blank' : '_self'}
                  >
                    {link.title}
                  </Link>
                </div>
              ))}
            </Accordeon>
          ))}
        </div>
      </div>
    </>
  );
};

export default MainFooter;
