import React, { FC } from 'react';

const SKIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="25" viewBox="0 0 1322 388">
      <path
        fill="#B0ED52"
        opacity="1.000000"
        stroke="none"
        d="
M387.787720,2.220124 
	C387.817871,39.521633 387.865479,76.823135 387.874207,114.124649 
	C387.888031,173.075790 388.027008,232.027512 387.808441,290.977844 
	C387.704773,318.946716 377.564301,342.974152 357.105560,362.223724 
	C340.531342,377.818359 320.684021,386.360718 297.045563,388.043945 
	C229.060593,388.001648 161.978897,387.991974 94.897209,387.991699 
	C93.902809,387.991699 92.908401,388.108948 91.481300,388.126648 
	C51.611477,384.127136 23.805965,363.997559 8.156352,327.642426 
	C4.800295,319.846069 3.335841,311.235504 1.000000,303.000000 
	C1.000000,231.312439 1.000000,159.624878 1.309533,87.234894 
	C8.450239,37.267887 47.834320,4.439901 92.386734,1.911975 
	C188.838394,1.989876 284.819733,1.992663 380.801056,2.007819 
	C383.129944,2.008187 385.458832,2.146272 387.787720,2.220124 
M69.948700,156.407700 
	C71.007698,173.446335 78.285995,187.084946 92.494186,196.679413 
	C104.819725,205.002548 119.056137,207.637833 133.404617,210.096573 
	C144.335953,211.969742 155.294067,214.112946 165.909683,217.253494 
	C173.053009,219.366806 177.390884,225.225891 177.816345,233.013916 
	C178.237686,240.726608 175.522232,247.566223 168.567551,251.111877 
	C162.618393,254.144882 155.988998,256.388367 149.408340,257.538361 
	C127.521851,261.363159 111.918640,252.160782 105.265160,232.147705 
	C92.749634,232.147705 80.184700,232.147705 67.312195,232.147705 
	C69.130089,253.321411 77.837021,270.114807 95.529617,281.684418 
	C107.412094,289.454681 120.774605,292.340149 134.786469,293.352020 
	C154.934555,294.806976 173.468231,290.683624 190.066223,278.868317 
	C191.211441,278.053101 192.392624,277.288452 194.066864,276.155243 
	C194.066864,280.671326 193.864044,284.500610 194.186249,288.285156 
	C194.287384,289.472931 195.780716,291.499176 196.669998,291.517334 
	C207.615768,291.740967 218.567688,291.663116 229.999725,291.663116 
	C229.999725,289.452332 229.999741,287.825531 229.999741,286.198761 
	C229.999710,274.032227 229.981598,261.865631 230.023209,249.699234 
	C230.028214,248.234741 229.830246,246.330292 230.624557,245.382553 
	C234.392654,240.886597 238.472229,236.651672 242.709824,232.030472 
	C254.134933,250.853622 265.257050,269.023621 276.177765,287.313904 
	C278.108612,290.547699 280.214294,291.920929 284.107513,291.809387 
	C294.598755,291.508728 305.104340,291.720398 315.604156,291.691833 
	C316.988556,291.688049 318.372406,291.469025 320.446045,291.290253 
	C302.717651,261.944366 285.422058,233.314941 267.933563,204.366180 
	C285.195435,185.407700 302.393524,166.519302 320.352051,146.795715 
	C304.705261,146.795715 290.261536,146.745255 275.819611,146.883255 
	C274.545837,146.895432 272.977997,147.858276 272.062988,148.853989 
	C261.129578,160.751556 250.304001,172.748108 239.425980,184.696701 
	C236.612427,187.787170 233.714828,190.801132 230.855850,193.850250 
	C230.481766,193.644012 230.107681,193.437790 229.733597,193.231552 
	C229.733597,161.667206 229.733597,130.102844 229.733597,98.505478 
	C217.653015,98.505478 206.080292,98.505478 193.861862,98.505478 
	C193.861862,105.990707 193.861862,113.135811 193.861862,120.953697 
	C182.371246,108.455437 172.189072,101.770210 156.823105,99.072319 
	C146.618179,97.280586 136.006973,96.717613 125.639885,97.138390 
	C94.659500,98.395836 70.177498,124.417145 69.948700,156.407700 
z"
      />
      <path
        fill="#4E585A"
        opacity="1.000000"
        stroke="none"
        d="
M1323.000000,291.750000 
	C1312.344238,291.950836 1301.687012,291.789856 1291.033569,291.923828 
	C1288.018311,291.961761 1286.224731,290.981049 1284.532104,288.497589 
	C1275.069214,274.612549 1265.426270,260.850220 1255.583740,246.669724 
	C1252.110474,250.202866 1248.709595,253.356903 1245.725830,256.865601 
	C1244.700073,258.071869 1244.339844,260.158966 1244.317993,261.851929 
	C1244.191162,271.651947 1244.256104,281.454407 1244.256104,291.551208 
	C1235.078247,291.551208 1226.325195,291.551208 1217.160522,291.551208 
	C1217.160522,256.197571 1217.160522,220.851288 1217.160522,185.262848 
	C1225.995850,185.262848 1234.760010,185.262848 1243.943604,185.262848 
	C1243.943604,198.204178 1243.943604,211.110031 1243.943604,225.441635 
	C1245.979736,223.608200 1247.145508,222.658813 1248.196899,221.596100 
	C1259.212769,210.462341 1270.119873,199.218094 1281.306274,188.258881 
	C1283.070435,186.530502 1286.048462,185.243347 1288.510864,185.163971 
	C1298.977783,184.826553 1309.461914,185.020630 1321.228271,185.020630 
	C1313.293213,192.993317 1306.124268,200.255951 1298.887695,207.450470 
	C1291.687256,214.608963 1284.418823,221.699142 1276.890381,229.104507 
	C1292.226440,249.969696 1307.271973,270.439575 1322.658691,290.954712 
	C1323.000000,291.000000 1323.000000,291.500000 1323.000000,291.750000 
z"
      />
      <path
        fill="#FEFFFD"
        opacity="1.000000"
        stroke="none"
        d="
M91.916397,1.838419 
	C47.834320,4.439901 8.450239,37.267887 1.309533,86.766235 
	C1.000000,58.404354 1.000000,29.808712 1.000000,1.000000 
	C31.020559,1.000000 61.041622,1.000000 91.515999,1.210159 
	C91.969315,1.420318 91.916397,1.838419 91.916397,1.838419 
z"
      />
      <path
        fill="#FEFFFE"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,303.468658 
	C3.335841,311.235504 4.800295,319.846069 8.156352,327.642426 
	C23.805965,363.997559 51.611477,384.127136 91.507088,388.333191 
	C91.965576,388.584503 92.000000,389.000000 92.000000,389.000000 
	C61.741821,389.000000 31.483644,389.000000 1.000000,389.000000 
	C1.000000,360.646179 1.000000,332.291748 1.000000,303.468658 
z"
      />
      <path
        fill="#E3F8BD"
        opacity="1.000000"
        stroke="none"
        d="
M92.386734,1.911975 
	C91.916397,1.838419 91.969315,1.420318 91.984657,1.210159 
	C190.354233,1.000000 288.708466,1.000000 387.531342,1.000000 
	C387.974487,1.204878 387.948944,1.409756 387.855530,1.917379 
	C385.458832,2.146272 383.129944,2.008187 380.801056,2.007819 
	C284.819733,1.992663 188.838394,1.989876 92.386734,1.911975 
z"
      />
      <path
        fill="#E1F9C0"
        opacity="1.000000"
        stroke="none"
        d="
M92.468658,389.000000 
	C92.000000,389.000000 91.965576,388.584503 91.939789,388.377991 
	C92.908401,388.108948 93.902809,387.991699 94.897209,387.991699 
	C161.978897,387.991974 229.060593,388.001648 296.612549,388.086090 
	C297.082855,388.161652 297.030273,388.579712 297.015137,388.789856 
	C228.979111,389.000000 160.958206,389.000000 92.468658,389.000000 
z"
      />
      <path
        fill="#4E585A"
        opacity="1.000000"
        stroke="none"
        d="
M1133.655518,288.861694 
	C1132.207397,290.072510 1130.990845,291.701691 1129.735962,291.732025 
	C1120.614502,291.952301 1111.485352,291.850464 1102.054443,291.850464 
	C1102.054443,256.151154 1102.054443,220.905884 1102.054443,185.340042 
	C1110.924805,185.340042 1119.820435,185.340042 1129.300537,185.340042 
	C1129.300537,206.656433 1129.300537,227.852097 1129.300537,249.047760 
	C1129.683228,249.169403 1130.065918,249.291031 1130.448608,249.412659 
	C1131.265625,248.111160 1132.113037,246.827576 1132.895264,245.505463 
	C1144.079224,226.601349 1155.316650,207.728271 1166.363281,188.744232 
	C1167.994141,185.941559 1169.712158,184.823715 1173.005493,184.932602 
	C1181.114746,185.200684 1189.238770,185.020386 1197.592529,185.020386 
	C1197.592529,220.763428 1197.592529,256.001038 1197.592529,291.547058 
	C1188.476807,291.547058 1179.572266,291.547058 1170.269043,291.547058 
	C1170.269043,270.563263 1170.269043,249.643707 1170.269043,228.724182 
	C1169.892822,228.578186 1169.516602,228.432190 1169.140503,228.286194 
	C1157.383423,248.370758 1145.626343,268.455292 1133.655518,288.861694 
z"
      />
      <path
        fill="#4F595B"
        opacity="1.000000"
        stroke="none"
        d="
M549.386353,147.821838 
	C559.130066,147.821533 568.413696,147.821533 578.390015,147.821533 
	C575.440552,154.668259 572.769043,160.888824 570.082581,167.102921 
	C557.090576,197.155075 544.151184,227.230194 531.069519,257.243286 
	C526.397095,267.963165 520.900574,278.228577 512.195068,286.362091 
	C502.168274,295.730133 486.925568,298.207367 474.553284,292.398071 
	C473.582611,291.942322 472.377472,290.644684 472.357666,289.715332 
	C472.184906,281.619690 472.257904,273.518799 472.257904,265.826752 
	C477.824921,266.715363 482.975067,267.878906 488.183350,268.271790 
	C493.296783,268.657471 496.980438,265.812500 499.578705,261.501953 
	C501.489624,258.331604 501.837769,255.414383 500.095825,251.765793 
	C484.482544,219.063400 469.077484,186.261536 453.615021,153.487137 
	C452.845428,151.855927 452.110474,150.208359 451.029968,147.849182 
	C462.179657,147.849182 472.742798,147.733856 483.294952,148.020935 
	C484.395508,148.050858 485.804932,150.304871 486.440948,151.795761 
	C496.027527,174.267120 505.506042,196.784607 515.019348,219.287247 
	C515.708374,220.917130 516.470703,222.516006 517.554932,224.917831 
	C524.917786,205.046982 531.955811,186.045883 538.999634,167.046967 
	C540.963989,161.748825 542.778442,156.384460 545.028259,151.209869 
	C545.647705,149.785034 547.590637,148.935593 549.386353,147.821838 
z"
      />
      <path
        fill="#4F595B"
        opacity="1.000000"
        stroke="none"
        d="
M743.557739,221.321136 
	C746.885254,212.650299 743.351929,206.339767 734.648010,204.895782 
	C732.033936,204.462082 729.339722,204.193695 726.698975,204.293350 
	C715.700500,204.708389 712.225342,207.627502 709.365662,218.702972 
	C700.357544,218.702972 691.278442,218.702972 681.732178,218.702972 
	C683.222839,205.138306 688.819824,194.176819 700.912170,188.190170 
	C719.412842,179.030960 738.583862,179.444061 757.378906,187.602783 
	C768.890442,192.599823 772.732849,202.664917 772.789429,214.486191 
	C772.876404,232.645706 772.706909,250.806992 772.882202,268.965240 
	C772.946472,275.615417 771.681030,282.888824 778.687927,287.476196 
	C778.989624,287.673676 779.033630,288.398956 779.041626,288.880981 
	C779.055237,289.700043 778.944580,290.521210 778.850342,291.845245 
	C769.307068,291.845245 759.853516,291.908051 750.403992,291.743256 
	C749.508911,291.727661 748.183167,290.444763 747.842224,289.474457 
	C746.823364,286.574646 746.194580,283.537689 745.315308,280.179077 
	C744.168823,281.221893 743.070312,282.151306 742.050049,283.159821 
	C729.918518,295.152008 715.313965,297.514862 699.555908,293.137177 
	C685.307739,289.178986 678.297363,277.065948 678.955261,259.501709 
	C679.490417,245.215363 689.346497,233.063721 703.726990,230.090149 
	C714.302429,227.903412 725.103638,226.829163 735.747925,224.941208 
	C738.382019,224.473984 740.797058,222.771759 743.557739,221.321136 
M717.960693,247.192245 
	C716.156006,248.019333 714.223328,248.647202 712.569580,249.709351 
	C706.662292,253.503540 705.995117,259.327301 706.972473,265.543335 
	C707.791687,270.753021 711.369629,273.701477 716.211975,274.841064 
	C728.592896,277.754730 741.644714,269.589874 744.109436,257.063751 
	C745.174011,251.653381 744.943909,245.988297 745.277527,240.817780 
	C736.054504,242.970871 727.394531,244.992523 717.960693,247.192245 
z"
      />
      <path
        fill="#4F595B"
        opacity="1.000000"
        stroke="none"
        d="
M1050.991699,223.540802 
	C1054.664673,224.025833 1055.417480,222.500626 1055.380981,219.545197 
	C1055.241333,208.246811 1055.324463,196.945679 1055.324463,185.334671 
	C1064.628540,185.334671 1073.528076,185.334671 1082.743774,185.334671 
	C1082.743774,220.578903 1082.743774,255.826187 1082.743774,291.464478 
	C1073.951904,291.464478 1065.049927,291.464478 1055.503174,291.464478 
	C1055.503174,276.577789 1055.503174,261.534058 1055.503174,246.254456 
	C1041.763550,246.254456 1028.660522,246.254456 1014.908997,246.254456 
	C1014.908997,261.255951 1014.908997,276.286163 1014.908997,291.579956 
	C1005.360779,291.579956 996.456970,291.579956 987.274170,291.579956 
	C987.274170,256.176361 987.274170,220.928604 987.274170,185.349548 
	C996.163025,185.349548 1005.080627,185.349548 1014.616455,185.349548 
	C1014.616455,197.769119 1014.616455,210.319565 1014.616455,223.541779 
	C1026.976562,223.541779 1038.733398,223.541779 1050.991699,223.540802 
z"
      />
      <path
        fill="#4F595B"
        opacity="1.000000"
        stroke="none"
        d="
M837.787842,294.930054 
	C831.522156,294.487610 825.600281,294.631622 819.955505,293.450928 
	C804.592285,290.237640 794.132507,280.892090 788.679565,266.206970 
	C782.763000,250.273071 782.150269,233.901459 786.540344,217.594193 
	C793.525513,191.646942 815.735168,178.199677 843.469238,182.541168 
	C865.634277,186.010880 878.579041,198.716949 881.556519,219.953491 
	C881.666443,220.737518 881.570557,221.550400 881.570557,222.597565 
	C872.109375,222.597565 862.821045,222.597565 853.302307,222.597565 
	C852.930969,221.645004 852.436035,220.609695 852.117859,219.522629 
	C849.210571,209.590561 845.227661,206.192963 835.785095,205.646652 
	C826.361328,205.101440 819.614136,208.779022 816.412720,217.397308 
	C811.512146,230.589767 811.375366,244.250610 815.305847,257.647095 
	C818.187012,267.467407 825.236755,271.638977 835.463196,271.147644 
	C843.741089,270.749939 850.260132,264.843323 852.638428,255.578522 
	C852.842651,254.782822 853.010803,253.977829 853.227905,253.037842 
	C862.705322,253.037842 872.117920,253.037842 881.535400,253.037842 
	C881.087280,270.172974 867.320618,292.927185 837.787842,294.930054 
z"
      />
      <path
        fill="#4E585A"
        opacity="1.000000"
        stroke="none"
        d="
M588.992310,185.021255 
	C593.447388,185.021255 597.405212,185.021255 601.880310,185.021255 
	C601.880310,195.730118 601.861084,206.002197 601.885071,216.274155 
	C601.915283,229.190125 605.507141,233.112244 618.415710,233.122467 
	C623.806885,233.126724 629.218018,231.921127 634.583679,231.042709 
	C637.726196,230.528259 638.580444,228.364212 638.533752,225.224686 
	C638.363159,213.739182 638.466431,202.249634 638.466553,190.761597 
	C638.466553,188.967041 638.466553,187.172485 638.466553,185.206879 
	C647.798279,185.206879 656.579346,185.206879 665.739685,185.206879 
	C665.739685,220.578339 665.739685,255.917664 665.739685,291.555023 
	C656.826172,291.555023 647.942322,291.555023 638.571533,291.555023 
	C638.571533,277.778992 638.571533,264.191101 638.571533,250.219955 
	C632.932007,251.385941 627.853577,253.154953 622.718445,253.338318 
	C613.793640,253.656952 604.674255,253.965286 595.925354,252.523865 
	C582.020203,250.232956 574.700562,241.139038 574.231506,226.690018 
	C573.789124,213.064178 574.136475,199.412720 574.136475,185.021255 
	C578.996155,185.021255 583.745667,185.021255 588.992310,185.021255 
z"
      />
      <path
        fill="#4F595B"
        opacity="1.000000"
        stroke="none"
        d="
M886.091492,189.198853 
	C885.938843,186.567871 885.987610,184.951279 889.066895,184.962845 
	C918.391174,185.073105 947.715942,185.045776 977.040588,185.073715 
	C977.344788,185.074005 977.648682,185.285049 978.206299,185.491653 
	C978.206299,192.509247 978.206299,199.620117 978.206299,207.432678 
	C967.637756,207.432678 957.100159,207.432678 946.087708,207.432678 
	C946.087708,235.867554 946.087708,263.608917 946.087708,291.600037 
	C936.821777,291.600037 928.048401,291.600037 918.535645,291.600037 
	C918.535645,263.794037 918.535645,235.951340 918.535645,207.571594 
	C907.399048,207.571594 896.984375,207.571594 886.091187,207.571594 
	C886.091187,201.246338 886.091187,195.461273 886.091492,189.198853 
z"
      />
      <path
        fill="#4E5859"
        opacity="1.000000"
        stroke="none"
        d="
M69.946449,155.935776 
	C70.177498,124.417145 94.659500,98.395836 125.639885,97.138390 
	C136.006973,96.717613 146.618179,97.280586 156.823105,99.072319 
	C172.189072,101.770210 182.371246,108.455437 193.861862,120.953697 
	C193.861862,113.135811 193.861862,105.990707 193.861862,98.505478 
	C206.080292,98.505478 217.653015,98.505478 229.733597,98.505478 
	C229.733597,130.102844 229.733597,161.667206 229.733597,193.231552 
	C230.107681,193.437790 230.481766,193.644012 230.855850,193.850250 
	C233.714828,190.801132 236.612427,187.787170 239.425980,184.696701 
	C250.304001,172.748108 261.129578,160.751556 272.062988,148.853989 
	C272.977997,147.858276 274.545837,146.895432 275.819611,146.883255 
	C290.261536,146.745255 304.705261,146.795715 320.352051,146.795715 
	C302.393524,166.519302 285.195435,185.407700 267.933563,204.366180 
	C285.422058,233.314941 302.717651,261.944366 320.446045,291.290253 
	C318.372406,291.469025 316.988556,291.688049 315.604156,291.691833 
	C305.104340,291.720398 294.598755,291.508728 284.107513,291.809387 
	C280.214294,291.920929 278.108612,290.547699 276.177765,287.313904 
	C265.257050,269.023621 254.134933,250.853622 242.709824,232.030472 
	C238.472229,236.651672 234.392654,240.886597 230.624557,245.382553 
	C229.830246,246.330292 230.028214,248.234741 230.023209,249.699234 
	C229.981598,261.865631 229.999710,274.032227 229.999741,286.198761 
	C229.999741,287.825531 229.999725,289.452332 229.999725,291.663116 
	C218.567688,291.663116 207.615768,291.740967 196.669998,291.517334 
	C195.780716,291.499176 194.287384,289.472931 194.186249,288.285156 
	C193.864044,284.500610 194.066864,280.671326 194.066864,276.155243 
	C192.392624,277.288452 191.211441,278.053101 190.066223,278.868317 
	C173.468231,290.683624 154.934555,294.806976 134.786469,293.352020 
	C120.774605,292.340149 107.412094,289.454681 95.529617,281.684418 
	C77.837021,270.114807 69.130089,253.321411 67.312195,232.147705 
	C80.184700,232.147705 92.749634,232.147705 105.265160,232.147705 
	C111.918640,252.160782 127.521851,261.363159 149.408340,257.538361 
	C155.988998,256.388367 162.618393,254.144882 168.567551,251.111877 
	C175.522232,247.566223 178.237686,240.726608 177.816345,233.013916 
	C177.390884,225.225891 173.053009,219.366806 165.909683,217.253494 
	C155.294067,214.112946 144.335953,211.969742 133.404617,210.096573 
	C119.056137,207.637833 104.819725,205.002548 92.494186,196.679413 
	C78.285995,187.084946 71.007698,173.446335 69.946449,155.935776 
M172.310318,180.084427 
	C179.499649,182.914703 186.688980,185.744980 193.735291,188.518951 
	C193.735291,178.203964 193.735291,167.322708 193.735291,156.000397 
	C187.949677,156.000397 182.483231,155.869354 177.027786,156.057526 
	C174.430481,156.147110 173.333588,155.215179 172.409653,152.786636 
	C163.926529,130.488586 132.428116,122.164948 113.793976,137.195816 
	C102.763100,146.093658 103.771553,161.176498 116.224907,167.853760 
	C120.829887,170.322876 126.098961,171.906891 131.256561,172.922958 
	C144.613022,175.554276 158.075958,177.645142 172.310318,180.084427 
z"
      />
      <path
        fill="#FDFDFD"
        opacity="1.000000"
        stroke="none"
        d="
M718.347656,247.103210 
	C727.394531,244.992523 736.054504,242.970871 745.277527,240.817780 
	C744.943909,245.988297 745.174011,251.653381 744.109436,257.063751 
	C741.644714,269.589874 728.592896,277.754730 716.211975,274.841064 
	C711.369629,273.701477 707.791687,270.753021 706.972473,265.543335 
	C705.995117,259.327301 706.662292,253.503540 712.569580,249.709351 
	C714.223328,248.647202 716.156006,248.019333 718.347656,247.103210 
z"
      />
      <path
        fill="#AFEC52"
        opacity="1.000000"
        stroke="none"
        d="
M171.903351,180.018097 
	C158.075958,177.645142 144.613022,175.554276 131.256561,172.922958 
	C126.098961,171.906891 120.829887,170.322876 116.224907,167.853760 
	C103.771553,161.176498 102.763100,146.093658 113.793976,137.195816 
	C132.428116,122.164948 163.926529,130.488586 172.409653,152.786636 
	C173.333588,155.215179 174.430481,156.147110 177.027786,156.057526 
	C182.483231,155.869354 187.949677,156.000397 193.735291,156.000397 
	C193.735291,167.322708 193.735291,178.203964 193.735291,188.518951 
	C186.688980,185.744980 179.499649,182.914703 171.903351,180.018097 
z"
      />
    </svg>
  );
};

export default SKIcon;
