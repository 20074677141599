import NextNProgress from 'nextjs-progressbar';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import {Provider} from 'react-redux';
import {wrapper} from 'src/store';

import Layout from '../global/ui/Layout';
import { useWindowSize } from 'shared/lib/hooks/useWindowSize';

import 'global/styles/normalize.scss';
import 'global/styles/globals.scss';
import 'global/styles/callibri.scss';
import 'react-calendar/dist/Calendar.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'swiper/scss';

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
});

function MyApp({ Component,...rest }: AppProps) {
  const {store, props: {pageProps}} = wrapper.useWrappedStore(rest);
  const { width } = useWindowSize();

  return (
    <>
      <NextNProgress color="var(--primary-color)" options={{ showSpinner: false }} />


          <main className={inter.className}>
            <Provider store={store}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
            </Provider>
          </main>
      <>
        {width > 768 && (
          <Script
            id="callibri"
            strategy="lazyOnload"
            src="https://cdn.callibri.ru/callibri.js"
            type="text/javascript"
          />
        )}
        <Script
          id="botfaqtor"
          strategy="lazyOnload"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            (function ab(){ var request = new XMLHttpRequest(); request.open('GET', "https://scripts.botfaqtor.ru/one/12204", false); request.send(); if(request.status == 200) eval(request.responseText); })(); 
            `,
          }}
        />
      </>
    </>
  );
}

export default MyApp;
