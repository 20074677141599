import React from 'react';
import styles from './styles.module.scss';

const MenuPreloader = () => {
  return (
    <div className={styles.preloaderWrap}>
    </div>
  );
};

export default MenuPreloader;
