import type { IMenus } from "src/models/IMenu";

export const createMenus = (
  isMobile: boolean | null,
  menus: IMenus | null,
  callbacks: Record<string, () => void>
) => {
  if (isMobile === null) return;

  if (!isMobile && !menus?.LEFT_SIDE && !menus?.TOP) {
    callbacks.desktop();
    return;
  }
};