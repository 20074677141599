import { axios } from '../../axios';

import type { ICategory, IHub } from 'entities/categories/model/ICategory';
import type { IFilter, IFilterDataMeta } from 'entities/filters/model/IFilter';
import type { ITag } from 'entities/tags';

export const CategoriesAPI = {
  getRootCategories() {
    return axios.get<{ data: ICategory[] }>(`/v1/categories`);
  },
  getCategoryById(id: string | number) {
    const params = {
      include: 'children,ancestors',
      maxDepth: 2,
    };
    return axios.get<{ data: ICategory }>(`/v1/categories/${id}`, { params });
  },
  getCategoryBySlug(slug: string) {
    const params = {
      include: 'children,ancestors',
      maxDepth: 2,
    };
    return axios.get<{ data: ICategory }>(`/v1/categories/bySlug/${slug}`, { params });
  },
  getCategoryFiltersByPath(
    path: string,
    location?: string,
    filters?: { [key: string]: string[] | string | number | boolean }
  ) {
    const params = {
      path,
      ...filters,
      ...(location && { location }),
    };

    return axios.get<{ data: IFilter[]; meta: IFilterDataMeta }>(`/v1/categories/filters`, { params });
  },
  getCategoryTags(categoryId: number | string) {
    return axios.get<{ data: ITag[] }>(`v1/categories/${categoryId}/tags`);
  },
  getCategoryTagsByPath(path: string) {
    return axios.get<{
      data: {
        current: ITag;
        parents: ITag[];
        tags: ITag[];
      };
    }>(`v1/categories/tags/byPath`, { params: { path } });
  },
  getCategoryByPath(path: string) {
    return axios.get<{ data: ICategory }>('/v1/categories/byPath', { params: { path, include: 'ancestors,children' } });
  },
  getHub(slug: string) {
    return axios.get<{ data: IHub }>(`/v1/categoriesHub/bySlug`, { params: { slug } });
  },
};
