import React, { FC } from 'react';
import Link from 'next/link';

import styles from './styles.module.scss';

const routes = [
  {
    title: 'Доставка',
    href: '/dostavka',
  },
  {
    title: 'Оплата',
    href: '/oplata',
  },
  {
    title: 'О компании',
    href: '/o-kompanii',
  },
  {
    title: 'Подъем и сборка',
    href: '/sborka',
  },
  {
    title: 'Контакты',
    href: '/kontakty',
  },
];

export const HeaderTop: FC = () => {
  return (
    <div className={styles.smallHeader}>
      <div className="container">
        <nav className={styles.smallHeaderNav}>
          {routes.map((route) => (
            <Link
              href={route.href}
              key={route.title}
              prefetch={false}
              className={styles.smallHeaderItem}>
              {route.title}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};
