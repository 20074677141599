import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Footer from '../../../templates/Footer';
import Header from 'templates/Header';
import { MainHead } from './MainHead';

import styles from './styles.module.scss';

const PhoneCallModal = dynamic(() => import('shared/ui/PhoneCallModal'), { ssr: false });
const AuthModal = dynamic(() => import('../../../components/Auth'), { ssr: false });

interface ILayoutChildren {
  children: React.ReactNode;
}

export default function Layout({ children }: ILayoutChildren) {
  const [isPhoneCallOpen, setIsPhoneCallOpen] = useState(false);

  const toggleIsPhoneCall = () => setIsPhoneCallOpen((prev) => !prev);

  const { pathname } = useRouter();

  useEffect(() => {
    if (isPhoneCallOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isPhoneCallOpen]);

  return (
      <>
          <MainHead />
          {isPhoneCallOpen && <PhoneCallModal isActive={isPhoneCallOpen} onClose={toggleIsPhoneCall} />}
          <AuthModal />
          {pathname !== '/checkout' && <Header openPhoneCallModal={toggleIsPhoneCall} />}
          <div className={styles.layout}>
            <div className={styles.content}>{children}</div>
          </div>
          <Footer openPhoneCallModal={toggleIsPhoneCall} />
      </>
  );
}
