import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import axios from 'axios';

import { useDispatch } from 'shared/lib/hooks/useDispatch';
import { useMatchScreen } from 'shared/lib/hooks/useMatchScreen';
import { setCartSize, setCompare, setFavorites, setBasketItems } from 'src/store/goods';
import { setUser } from 'src/store/auth';
import { useSelector } from 'shared/lib/hooks/useSelector';
import { getCartData } from './libs/getCartData';
import { createMenus } from './libs/createMenus';
import type { IMenus } from 'src/models/IMenu';

import { HeaderTop } from '../../global/ui/HeaderTop';
import { HeaderBody } from './ui/HeaderBody';
import { HeaderMenu } from './ui/HeaderMenu';

import styles from './styles.module.scss';

import { api } from 'global/api';

type Props = {
  openPhoneCallModal: () => void;
};

const Header: FC<Props> = ({ openPhoneCallModal }) => {
  const [menus, setMenus] = useState<IMenus | null>(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const isAuth = Boolean(Cookies.get('token'));
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = useMatchScreen(1080);

  const fetchTopMenu = async () => {
    try {
      const topMenuRes = await axios.get('/api/menu?type=TOP');
      setMenus((prev) => ({ ...prev, TOP: topMenuRes?.data || null }));
    } catch (error) {
      console.error(error);
    }
  };

  const setCompareFromCookie = () => {
    const ids = Cookies.get('compareIds');
    if (ids) {
      dispatch(setCompare(ids.split(',').map(Number)));
    }
  };

  const getGlobalInfo = async () => {
    try {
      const globalInfoRes = await api.getGlobalInfo();
      if (globalInfoRes) {
        const { favorite_products_ids, basket_size, compare_products_ids, basket_items } = globalInfoRes.data.data;

        dispatch(setBasketItems(basket_items));
        dispatch(setFavorites(favorite_products_ids));
        dispatch(setCartSize(basket_size));

        if (compare_products_ids) {
          dispatch(setCompare(compare_products_ids));
        } else {
          setCompareFromCookie();
        }
      } else {
        setCompareFromCookie();
      }
    } catch (error) {
      console.error(error);
      setCompareFromCookie();
    }
  };

  const setCartData = async () => {
    const cartData = await getCartData();

    const newCartSize = cartData.initialGoods.reduce((acc, item) => acc + item.quantity, 0);

    dispatch(setCartSize(newCartSize));
  };

  const getUser = async () => {
    if (Boolean(user)) return;
    try {
      const user = await api.getProfile();
      dispatch(setUser(user.data.data));
    } catch (e) {
      console.error(e);
    }
  };

  const handleHoverBurger = async () => {
    if (menus?.LEFT_SIDE) return menus.LEFT_SIDE;
    try {
      const sideMenuRes = await axios.get('/api/menu?type=LEFT_SIDE');
      setMenus((prev) => ({ ...prev, LEFT_SIDE: sideMenuRes.data || null }));
      return sideMenuRes.data || null;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGlobalInfo();
    isAuth && getUser();
  }, [isLoggedIn, router.asPath]);

  useEffect(() => {
    createMenus(isMobile, menus, {
      desktop: fetchTopMenu,
    });
  }, [isMobile]);

  useEffect(() => {
    if (router.asPath !== '/cart') setCartData();
  }, [router.asPath]);

  return (
    <header className={styles.mainHeader}>
      <div className={styles.headerWrapper}>
        <HeaderTop />
        <HeaderBody onHoverBurger={handleHoverBurger} />
      </div>
      <div className={styles.headerMenu}>
        <HeaderMenu mainMenu={menus?.TOP} openPhoneCallModal={openPhoneCallModal} onHoverBurger={handleHoverBurger} />
      </div>
    </header>
  );
};

export default Header;
