import React, { FC } from 'react';

import Contacts from './Contacts';
import Subscription from './Subscription';
import Socials from './Socials';
import MainFooter from './Main';
import Credentials from './Credentials';
import BannerBlock from './BannerBlock';

import styles from './styles.module.scss';

type Props = {
  openPhoneCallModal: () => void;
};

const MainHeader: FC<Props> = ({ openPhoneCallModal }) => {
  return (
    <>
      <div className={styles.container}>
        <Subscription />
      </div>
      <footer className={styles.footer}>
        <BannerBlock openPhoneCallModal={openPhoneCallModal} />
        <MainFooter />
        <Contacts openPhoneCallModal={openPhoneCallModal} />
        <Credentials />
        <Socials />
      </footer>
    </>
  );
};

export default MainHeader;
