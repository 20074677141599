import {getHref} from 'features/categories/lib/getHref';
import React, { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { ICategory } from '../../../entities/categories/model/ICategory';
import PlaceholderImage from 'assets/images/placeholder_small.png';
import { getImageVariantProps } from 'shared/lib/images';

import styles from './styles.module.scss';

const makeClassName = (type: string): string => {
  return type === 'tile' ? 'container' : `${styles.listContainer}  container`;
};

type Props = {
  categories: ICategory[];
  type?: 'tile' | 'list';
  className?: string;
  onCategoryClick?: () => void;
};

const PopularCategories: FC<Props> = ({ categories, type = 'tile', className, onCategoryClick = () => {} }) => {
  return (
    <div className={`${makeClassName(type)} ${className}`}>
      <div className={styles.categoriesList}>
        {categories?.map((category) => (
          <Link
            onClick={onCategoryClick}
            key={category.id}
            href={getHref(category)}
            className={styles.category}
            passHref
          >
            <div className={styles.categoryContent}>
              <div className={styles.categoryImage}>
                {category.image ? (
                  <Image {...getImageVariantProps(category.image.variants, 'small_webp')} alt={`Категория: ${category.name}`} />
                ) : (
                  <Image src={PlaceholderImage} alt="PlaceholderImage" fill />
                )}
              </div>
              <div className={styles.categoryTitle}>{category.name}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export { PopularCategories };
