import type { IconType } from './type';

export const ChairSVG = ({ width = 16, height = 23, fill = 'none', className }: Partial<IconType>) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 23"
      fill={fill}
    >
      <g clipPath="url(#clip0_5067_56104)">
        <path d="M15 15.25V21.25" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 21.25V15.25" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M12.0001 13.25L14.0001 6.25C16.0001 -0.75 7.98851e-05 -0.75 2.00008 6.25L4.00008 13.25"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.73 13.25H2.27C1.5686 13.25 1 13.8186 1 14.52V14.98C1 15.6814 1.5686 16.25 2.27 16.25H13.73C14.4314 16.25 15 15.6814 15 14.98V14.52C15 13.8186 14.4314 13.25 13.73 13.25Z"
          stroke="#212121"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5067_56104">
          <rect width="16" height="22.25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
