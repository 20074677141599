import React, { FC } from 'react';
import styles from './styles.module.scss';
import VkIcon from '../Credentials/VkIcon';
import SKIconPrimary from './SKIconPrimary';

const Socials: FC = () => {
  const year = new Date().getFullYear();

  return (
    <div className={`${styles.socialsList} container`}>
      <div className={styles.credentials}>
        <div className={styles.copyright}>&copy;&nbsp;{year}&nbsp;ООО &laquo;Вобаза&raquo;</div>
        <div>
          <a href="https://vk.com/vobaza_official" target="_blank" className={styles.socialsListItem} rel="noreferrer">
            <VkIcon />
          </a>
          <a
            href="https://navigator.sk.ru/orn/1123813"
            target="_blank"
            className={styles.socialsListItem}
            rel="noreferrer"
          >
            <SKIconPrimary />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Socials;
