import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IMenuItem } from 'src/models/IMenu';

export interface IMenuState {
  mainMenu: IMenuItem[];
}

const initialState: IMenuState = {
  mainMenu: [],
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    /** Устанавливает сайд меню для каталога в футере */
    setMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.mainMenu = action.payload;
    }
  },
});

export const { setMenu} = menusSlice.actions;

export default menusSlice.reducer;
