import { useEffect } from 'react';

import { HeaderMiddleMobileMenu } from './HeaderMiddleMobileMenu';
import { HeaderSmallSizeMobileMenu } from './HeaderSmallSizeMobileMenu';
import { useWindowSize } from 'shared/lib/hooks/useWindowSize';

import type { MenuProps } from './types';

import styles from './styles.module.scss';

export const HeaderMobileMenu = ({ close, isOpen = true }: MenuProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    //решает баг со скролом в модалке когда главная страница скролится параллельно меню
    document.body.style['overflow-y'] = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <div className={`${styles.headerMobileMenu} ${isOpen ? styles.active : ''}`}>
      {width > 768 && <HeaderMiddleMobileMenu close={close} isOpen={isOpen} />}
      {width <= 768 && <HeaderSmallSizeMobileMenu close={close} isOpen={isOpen} />}
    </div>
  );
};
