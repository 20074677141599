import React, { FC, FormEvent, KeyboardEventHandler, SyntheticEvent, useState } from 'react';
import { useRouter } from 'next/router';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';

import styles from './styles.module.scss';

const Search: FC = () => {
  const router = useRouter();
  const [value, setValue] = useState('');

  const setValueHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    router.push({
      pathname: '/search',
      query: {
        query: value,
        limit: 40,
        page: 1,
      },
    });
    setValue('');
  };
  const onEnterPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <form className={styles.searchContainer} onSubmit={onSubmit}>
      <input
        id="searchId"
        name="text"
        className={styles.search}
        value={value}
        onChange={setValueHandler}
        title="Найти товар или категорию..."
        placeholder="Искать по Вобазе"
        onKeyDown={onEnterPress}
      />
      <button className={styles.searchButton} type="submit" aria-label="Искать товар или категорию">
        <Icon className={styles.searchIcon} name="Magnifier"></Icon>
      </button>
    </form>
  );
};
export default Search;
